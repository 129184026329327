import React from 'react'
import Seo from '../../component/Seo'
import Layout from '../../component/Layout/Layout'
import { Link } from 'gatsby'
import bg from '../../images/develop/picture.svg'
import './_develop.scss'

const DevelopPage = () => {
  return (
    <Layout>
      <Seo title="Demo Mortgage"/>
      <section className="develop-page">
        <div className="develop-page__inner">
          <div className="develop-page__left">
            <h2 className="develop-page__headline">
              This page has been excluded from a demo version.
            </h2>
            <Link
              className="develop-page__link"
              to="/"
            >
              To Home Page
            </Link>
          </div>
          <div className="develop-page__right">
            <img src={bg} alt="bg develop" className="develop-page__right__image"/>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default DevelopPage